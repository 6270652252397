import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import DataTable from '../../datatable/DataTable';
import { Axios } from '../../components/Axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Swal from 'sweetalert2';


const Banner = () => {

    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            const bannersData = await Axios.get('api/getAllBanners');
            let banners = bannersData.data.data;
            setData(banners);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '150px',
            },
            {
                name: <th className='fs-6 '>Page</th>,
                selector: (row) => row.page_name,
                width: '200px',
            },

            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '200px',
            },

            {
                name: <th className='fs-6'>Front Image</th>,
                selector: (row) => (
                    row.front_image && <img width="60px" height="60px" className='p-2' src={`/assets/banner/${row.front_image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },

            {
                name: <th className='fs-6'>Back Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`/assets/banner/${row.back_image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },



            // {
            //   name: <th className='fs-6 '>status</th>,
            //   selector: 'cta',
            //   width: '200px',
            // },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Link to={`/update-banner/${row.page_id}`}>
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}>
                                <FaEdit />
                            </Button>
                        </Link>
                        {/* /${row._id} */}
                        {/* <Button className='_delete_btn_ bg-danger text-white' onClick={() => confirmDelete(row._id)} variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}><FaTrashAlt /></Button> */}
                    </div>
                ),
                right: true,
          

            },
        ],
    };


    // functionality

    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`api/DeleteProduct/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#b98d58',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };



    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b98d58',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };





    return (
        <>
            <div className='banner-page'>
                <Helmet>
                    <title>Banners</title>
                </Helmet>
                <section>
                    <ToastContainer />
                    <section className='product-section'>
                        <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                            <div className="">
                                <span className='fw-bold'>Banners</span>
                            </div>
                        </div>
                    </section>
                </section>
                <section className=' mt-2'>
                    <Card >
                        <div className="main ">
                            <DataTable data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>
            </div>
        </>
    )
}

export default Banner