import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Axios } from '../components/Axios';
import Card from 'react-bootstrap/Card';
import Datatables from '../datatable/DataTable';
import { FaShare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import EnquiryModel from './inquiry/EnquiryModel';
import { GiPlatform } from 'react-icons/gi'
import { MdMarkEmailUnread } from 'react-icons/md'
import { BiSolidCategoryAlt } from 'react-icons/bi'

const Dashboard = () => {

    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [count, setCount] = useState([]);
    const [id, setId] = useState('');
    const [filterBy, setFilterBy] = useState('0')
    const [showUpdate, setShowUpdate] = useState(false);

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        setId(row._id);
    };



    const getData = async () => {

        try {
            const countData = await Axios.get('api/getAllCounts');
            let counts = countData.data.data;
            setCount(counts);

            const enquiriesData = await Axios.get('api/getAllInquiry');
            let enquiries = enquiriesData.data.data;
            setData(enquiries);
            setNewData(enquiries)

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }


    }

    useEffect(() => {
        getData();
        window.scrollTo(0, 0)
    }, []);



    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            // {
            //     name: <th className='fs-6 '>Inquiry</th>,
            //     selector: 'product_id',
            //     width: '150px',
            // },

            {
                name: <th className='fs-6 '>Name</th>,
                selector: (row) => row.name,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Phone</th>,
                selector: (row) => row.phone,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Product Ids</th>,
                selector: (row) => row.product_id,
                width: '400px',
            },
            {
                name: <th className='fs-6'>Status</th>,
                cell: (row, index) => row.status == 0 ? <span className='bg-info text-white p-1 rounded'>Pending</span> : row.status == 1 ? <span className=' bg-success text-white p-1 rounded'>Complete</span> : '-',
                width: '220px',
            },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        {/* <Link to={`/update-inquiry/${row._id}`}>
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}>
                                <FaEdit />
                            </Button>
                        </Link> */}
                        <Button className='_delete_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}
                            onClick={() => handleShowUpdate(row)}
                        ><FaShare /></Button>
                    </div>
                ),
                right: true,
                // width: '200px',

            },
        ],
    };

    useEffect(() => {
        setData(newData?.filter(data => data.status == 0))
    }, [newData])




    const filterByStatus = (status) => {
        if (status == "") {
            setData(newData);
        } else {
            setData(newData?.filter(data => data.status == status));
        }
        setFilterBy(status)
    }

    return (
        <div>
            <section className='cards-s'>
                <div className='cards-d'>
                    <div className='cards-grid'>
                        {count?.map((data, index) => {
                            return (
                                <Paper key={index} elevation={4} className='card-paper'>
                                    <div className='cards'>
                                        <div className='content-d'>
                                            <span className='count'>{data.count}</span>
                                            <h2>{data.name}</h2>
                                            <p>(Active)</p>
                                        </div>
                                        <div className='icon-d'>
                                            {index === 0 && <GiPlatform />}
                                            {index === 1 && <BiSolidCategoryAlt />}
                                            {index === 2 && <MdMarkEmailUnread />}

                                        </div>
                                    </div>
                                </Paper>
                            )
                        }
                        )}
                    </div>
                </div>
            </section>


            <section>
                <ToastContainer />
                <section className='inquiry-section'>
                    <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                        <div className="">
                            <span className='fw-bold'>Enquiries</span>
                            <div>
                                <strong >Status :-</strong>
                                <select className="status select_status filter"
                                    value={filterBy}
                                    onChange={(e) => filterByStatus(e.target.value)}
                                >
                                    <option value="">All</option>
                                    <option value="0">Pending</option>
                                    <option value="1">Complete</option>


                                </select>
                            </div>
                        </div>
                        <div className="">
                            <Link to="/inquiry">
                                <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#b98d58', borderRadius: '0' }}  >
                                    View All
                                </Button>
                            </Link>
                        </div>
                    </div>
                </section>
            </section>

            <section className='enq-s m-2'>
                <Card >
                    <div className="main ">
                        <Datatables data={data?.slice(0, 5)} columns={tableData.columns} />
                    </div>
                </Card>
            </section>


            <EnquiryModel id={id} show={showUpdate} setShowUpdate={setShowUpdate} />

        </div >
    )
}

export default Dashboard;