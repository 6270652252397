import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import OutlinedInput from '@mui/material/OutlinedInput';
import { Axios } from '../components/Axios';
import { notify } from '../components/common/notify';

const Profile = () => {

  //   {
  //   product_ids: [],
  //   product_name: []
  // }

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    address: ''
  })


  const getData = () => {
    Axios.get('/api/getProfile')
      .then((response) => {
        const settingData = response?.data?.data[0];
        if (settingData) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ['id']: settingData._id,
            ['email']: settingData.email,
            ['name']: settingData.name,
            ['address']: settingData.address,
            ['phone']: settingData.phone,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);


  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };





  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async () => {
    try {
      const response = await Axios.put(`/api/updateProfile`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (response.status === 200 || 201) {
        notify();
        setTimeout(() => {
          window.location.reload(false)
        }, 1500);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }


  return (
    <>
      <div>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <section className="Profile_update">
          <div className="row">
            <ToastContainer />
            <section className='inq-section'>
              <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                <div className="">
                  <span className='fw-bold'>Profile</span>
                </div>

              </div>
            </section>
          </div>
        </section>

        <section class="card cardSectionBorder mt-3">

          <div className=' m-2'>
            <Form className='row m-0' onSubmit={handleSubmit(onSubmit)} >

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Name</Form.Label>
                <Form.Control className="" type="text" placeholder="name" value={formData.name}
                  {...register("name", {
                    required: false,
                    onChange: (e) => handleInputChange('name', e.target.value)
                  })}
                />

                {errors.name && (
                  <div className='errMsg text-danger'>name is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Email</Form.Label>
                <Form.Control className="" type="email" placeholder="Email" value={formData.email}
                  {...register("Email", {
                    required: false,
                    onChange: (e) => handleInputChange('email', e.target.value)
                  })}
                />

                {errors.Email && (
                  <div className='errMsg text-danger'>Email is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="Phone"
                  value={formData.phone}
                  {...register("phone", {
                    required: false,
                    onChange: (e) => handleInputChange('phone', e.target.value)
                  })}
                />

                {errors.phone && (
                  <div className='errMsg text-danger'>Phone is Required</div>
                )}
              </Form.Group>


              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-1">
                <Form.Label>Address</Form.Label>
                <Form.Control className="" rows="5" as="textarea" placeholder="address" value={formData.address}
                  {...register("address", {
                    required: false,
                    onChange: (e) => handleInputChange('address', e.target.value)
                  })}
                />

              </Form.Group>



              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </>
  )
}

export default Profile