import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Datatables from '../../datatable/DataTable';
import { Axios } from '../../components/Axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Swal from 'sweetalert2';

const Blog = () => {


    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);


    const getData = () => {
        Axios.get('api/getAllBlogs').then((response) => {
            console.log(response?.data?.data);
            setData(response?.data?.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '150px',
            },

            {
                name: <th className='fs-6 '>Content</th>,
                selector: (row) => row.content,
                width: '200px',
            },
            // {
            //     name: <th className='fs-6 '>status</th>,
            //     selector: 'status',
            //     width: '200px',
            // },


            {
                name: <th className='fs-6'>Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`/assets/blogs/${row.image}`} alt="Blog Image" />
                ),
                width: '200px',
            },


            // {
            //     name: <th className='fs-6'>Image</th>,
            //     selector: (row) => (
            //         <img width="60px" height="60px" className='p-2' src={`/assets${row.image}`} alt="Portfolio Image" />
            //     ),
            //     width: '180px',
            // },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Link to={`/update-blog/${row._id}`}>
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}>
                                <FaEdit />
                            </Button>
                        </Link>
                        <Button className='_delete_btn_ bg-danger text-white' variant='contained' onClick={() => confirmDelete(row._id)} style={{ float: 'right', backgroundColor: '#3e3d52' }}><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,
       

            },
        ],
    };



    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/deleteBlog/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#b98d58',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };



    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b98d58',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };



    return (
        <>
            <div className='blog-page'>
                <Helmet>
                    <title>Blog</title>
                </Helmet>
                <section>
                    <ToastContainer />
                    <section className='blog-section'>
                        <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                            <div className="">
                                <span className='fw-bold'>Blog</span>
                            </div>
                            <div className="">
                                <Link to="/add-blog">
                                    <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#b98d58', borderRadius: '0' }}  >
                                        Add Blog
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </section>
                </section>
                <section className=' mt-2'>
                    <Card >
                        <div className="main ">
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>
            </div>
        </>
    )
}

export default Blog