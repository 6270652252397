import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { Axios } from '../../components/Axios';
import { notify, notifyErr } from '../../components/common/notify';


const UpdateBanner = () => {

    const { id } = useParams()

    const [frontImage, setFrontImage] = useState('');
    const [backImage, setBackImage] = useState('');
    // const [id, setId] = useState('')

    const [formData, setFormData] = useState({
        id: '',
        title: '',
        front_image: '',
        back_image: '',
        btn_name: '',
        description: '',
        tag: '',
        seo_title: '',
        seo_description: '',
        seo_canonical: '',
        seo_robot: '',
        seo_script: '',
        seo_feature_image: '',
    });


    const getData = async () => {
        try {
            const bannerData = await Axios.get(`/api/getBanners/${id}`);
            let banner = bannerData.data.data[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                ['id']: banner._id,
                ['title']: banner.title,
                ['btn_name']: banner.btn_name,
                ['description']: banner.description,
                ['tag']: banner.tag,
                ['seo_title']: banner.seo_title,
                ['seo_description']: banner.seo_description,
                ['seo_canonical']: banner.seo_canonical,
                ['seo_robot']: banner.seo_robot,
                ['seo_script']: banner.seo_script,
                ['seo_feature_image']: banner.seo_feature_image,
            }));
            // setId(banner._id)
            setFrontImage(banner.front_image)
            setBackImage(banner.back_image)

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, [id]);





    const { register, handleSubmit, formState: { errors } } = useForm();
    // const onSubmit = (data) => {
    //   console.log(data)
    // }


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };




    const onSubmit = async () => {
        console.log(formData);

        try {
            const response = await Axios.put('/api/updateBanners', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Banner Update Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }



    return (
        <>
            <div className='banner-page'>
                <Helmet>
                    <title>Update Banner</title>
                </Helmet>
                <section className="product-add">
                    <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                        <div className="">
                            <span className='fw-bold'>Update Banner</span>
                        </div>

                    </div>
                </section>
                <ToastContainer />
                <section class="card  cardSectionBorder mt-3">
                    <div className='m-2'>
                        <Form className='row' onSubmit={handleSubmit(onSubmit)}>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={formData?.title || ''}
                                    {...register("title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('title', e.target.value)
                                    })}
                                />

                                {errors.title && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}
                            </Form.Group>


                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Button Text</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Button Text"
                                    value={formData?.btn_name || ''}
                                    {...register("btn_name", {
                                        required: false,
                                        onChange: (e) => handleInputChange('btn_name', e.target.value)
                                    })}
                                />

                                {errors.btn_name && (
                                    <div className='errMsg text-danger'>Button is required</div>
                                )}
                            </Form.Group>


                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Tag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={formData?.tag || ''}
                                    {...register("tag", {
                                        required: false,
                                        onChange: (e) => handleInputChange('tag', e.target.value)
                                    })}
                                />

                                {errors.tag && (
                                    <div className='errMsg text-danger'>tag is required</div>
                                )}
                            </Form.Group>



                            <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Description</Form.Label>
                                <Form.Control className="" rows="5" as="textarea"
                                    value={formData?.description || ''}
                                    {...register("description", {
                                        required: false,
                                        onChange: (e) => handleInputChange('description', e.target.value)
                                    })}
                                />

                                {errors.description && (
                                    <div className='errMsg text-danger'>description is required</div>
                                )}

                            </Form.Group>

                            <Form.Group
                                className="mt-2"
                            >

                                <div className='row'>
                                    <div className='col-6'>
                                        <Form.Label>Front Image</Form.Label>
                                        <Form.Control type="file" name="file"
                                            {...register("frontImage", {
                                                required: false,
                                                onChange: (e) => handleInputChange('front_image', e.target.files[0])
                                            })}
                                        />

                                        {errors.frontImage && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            {formData?.front_image == "" ?

                                                <img src={`/assets/banner/${frontImage}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                :
                                                <img src={URL.createObjectURL(formData?.front_image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>

                            <Form.Group
                                className="mt-2"
                            >

                                <div className='row'>
                                    <div className='col-6'>
                                        <Form.Label>Back Image</Form.Label>
                                        <Form.Control type="file" name="file"
                                            {...register("backImage", {
                                                required: false,
                                                onChange: (e) => handleInputChange('back_image', e.target.files[0])
                                            })}
                                        />

                                        {errors.backImage && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>
                                            {formData?.back_image == "" ?

                                                <img src={`/assets/banner/${backImage}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                :
                                                <img src={URL.createObjectURL(formData?.back_image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>



                            <div className="col-lg-12">
                                <span className=' fw-bold'>SEO Section</span>
                            </div>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="SEO Title"
                                    value={formData?.seo_title || ''}
                                    {...register("Seo_title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('seo_title', e.target.value)
                                    })}
                                />

                                {errors.Seo_title && (
                                    <div className='errMsg text-danger'>Seo_title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Canonical</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Canonical"
                                    value={formData?.seo_canonical || ''}
                                    onChange={(e) => handleInputChange('seo_canonical', e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label htmlFor="Robot">Robot</Form.Label>
                                <Form.Select
                                    id="Robot"
                                    aria-label="Default select example"
                                    value={formData?.seo_robot || ''}
                                    onChange={(e) => handleInputChange('seo_robot', e.target.value)}

                                >
                                    <option value="" >Index,Follow</option>
                                    <option value="index,follow" >Index,Follow</option>
                                    <option value="index,no-follow">Index,No-Follow</option>
                                    <option value="no-index,follow">No-Index,Follow</option>
                                    <option value="no-index,no-follow">No-Index,No-Follow</option>
                                </Form.Select>

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Script</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Script"
                                    value={formData?.seo_script || ''}
                                    onChange={(e) => handleInputChange('seo_script', e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                            >
                                <Form.Label>Feature Image</Form.Label>
                                <Form.Control type="url" name="url"
                                    value={formData?.seo_feature_image || ''}
                                    onChange={(e) => handleInputChange('seo_feature_image', e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control as="Textarea" placeholder="SEO Description"
                                    value={formData?.seo_description || ''}
                                    onChange={(e) => handleInputChange('seo_description', e.target.value)} />

                            </Form.Group>

                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                                    Update Banner
                                </Button>
                            </div>
                        </Form>





                    </div>
                </section>

            </div>
        </>
    )
}

export default UpdateBanner