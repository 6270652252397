import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { Axios } from '../../components/Axios';
import { useParams } from 'react-router-dom';
import { notify, notifyErr } from '../../components/common/notify';


const UpdateBlog = () => {
    const { id } = useParams()
    const [pending, setPending] = useState(true);
    const [frontImage, setFrontImage] = useState('');
    const [backImage, setBackImage] = useState('');

    const [formData, setFormData] = useState({
        title: '',
        bg_image: '',
        content: '',
        image: '',
        seo_title: '',
        seo_description: '',
        seo_canonical: '',
        seo_robot: '',
        seo_script: '',
        seo_feature_image: '',
    });


    const getData = async () => {
        try {
            const blogData = await Axios.get(`/api/getSingleBlog/${id}`);
            let blog = blogData.data.data[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                ['id']: blog._id,
                ['title']: blog.title,
                ['content']: blog.content,
                ['seo_title']: blog.seo_title,
                ['seo_description']: blog.seo_description,
                ['seo_canonical']: blog.seo_canonical,
                ['seo_robot']: blog.seo_robot,
                ['seo_script']: blog.seo_script,
                ['seo_feature_image']: blog.seo_feature_image,
            }));
            // setId(blog._id)
            setBackImage(blog.bg_image)
            setFrontImage(blog.image)

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, [id]);





    const { register, handleSubmit, formState: { errors } } = useForm();


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };




    const onSubmit = async () => {
        console.log(formData);

        try {
            const response = await Axios.put('/api/updateBlog', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('blog updated Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }


    return (
        <>
            <div className='Update-page'>

                <Helmet>
                    <title>Update Blog</title>
                </Helmet>
                <section className="blog-Update">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Update Blog</span>
                        </div>

                    </div>
                </section>
                <ToastContainer />
                <section class="card  cardSectionBorder mt-3">
                    <div className=' m-2'>
                        <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={formData?.title || ''}
                                    {...register("title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('title', e.target.value)
                                    })}
                                />

                                {errors.title && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <Form.Control className="" rows="5" as="textarea"
                                    value={formData?.content || ''}
                                    {...register("content", {
                                        required: false,
                                        onChange: (e) => handleInputChange('content', e.target.value)
                                    })}
                                />

                                {errors.content && (
                                    <div className='errMsg text-danger'>content is required</div>
                                )}

                            </Form.Group>

                            <Form.Group
                                className="mt-2"
                            >

                                <div className='row'>
                                    <div className='col-6'>
                                        <Form.Label>Blog Image</Form.Label>
                                        <Form.Control type="file" name="file"
                                            {...register("image", {
                                                required: false,
                                                onChange: (e) => handleInputChange('image', e.target.files[0])
                                            })}
                                        />

                                        {errors.image && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            {formData?.image == "" ?

                                                <img src={`/assets/blogs/${frontImage}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                :
                                                <img src={URL.createObjectURL(formData?.image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>

                            <Form.Group
                                className="mt-2"
                            >

                                <div className='row'>
                                    <div className='col-6'>
                                        <Form.Label>Background Image</Form.Label>
                                        <Form.Control type="file" name="file"
                                            {...register("bg_image", {
                                                required: false,
                                                onChange: (e) => handleInputChange('bg_image', e.target.files[0])
                                            })}
                                        />

                                        {errors.bg_image && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>
                                            {formData?.bg_image == "" ?

                                                <img src={`/assets/blogs/${backImage}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                :
                                                <img src={URL.createObjectURL(formData?.bg_image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>



                            <div className="col-lg-12">
                                <span className=' fw-bold'>SEO Section</span>
                            </div>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="SEO Title"
                                    value={formData?.seo_title || ''}
                                    {...register("Seo_title", {
                                        required: false,
                                        onChange: (e) => handleInputChange('seo_title', e.target.value)
                                    })}
                                />

                                {errors.Seo_title && (
                                    <div className='errMsg text-danger'>Seo_title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Canonical</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Canonical"
                                    value={formData?.seo_canonical || ''}
                                    onChange={(e) => handleInputChange('seo_canonical', e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label htmlFor="Robot">Robot</Form.Label>
                                <Form.Select
                                    id="Robot"
                                    aria-label="Default select example"
                                    value={formData?.seo_robot || ''}
                                    onChange={(e) => handleInputChange('seo_robot', e.target.value)}

                                >
                                    <option value="" >Index,Follow</option>
                                    <option value="index,follow" >Index,Follow</option>
                                    <option value="index,no-follow">Index,No-Follow</option>
                                    <option value="no-index,follow">No-Index,Follow</option>
                                    <option value="no-index,no-follow">No-Index,No-Follow</option>
                                </Form.Select>

                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Script</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Script"
                                    value={formData?.seo_script || ''}
                                    onChange={(e) => handleInputChange('seo_script', e.target.value)}

                                />

                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                            >
                                <Form.Label>Feature Image</Form.Label>
                                <Form.Control type="url" name="url"
                                    value={formData?.seo_feature_image || ''}
                                    onChange={(e) => handleInputChange('seo_feature_image', e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control as="Textarea" placeholder="SEO Description"
                                    value={formData?.seo_description || ''}
                                    onChange={(e) => handleInputChange('seo_description', e.target.value)} />

                            </Form.Group>




                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                                    Update Blog
                                </Button>
                            </div>
                        </Form>

                    </div>
                </section>

            </div>
        </>
    )
}

export default UpdateBlog