import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from '../datatable/DataTable';
import Card from 'react-bootstrap/Card';
import { Axios } from '../components/Axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../components/common/notify';


const Category = () => {

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  // add category
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [featured, setFeatured] = useState('');
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);


  // update Category
  const [id, setId] = useState('')
  const [updateName, setUpdateName] = useState('');
  const [updateFeatured, setUpdateFeatured] = useState('');
  const [updateImage, setUpdateImage] = useState('');
  const [updatedImage, setUpdatedImage] = useState('');

  const [showUpdate, setShowUpdate] = useState(false);

  const handleShowUpdate = (row) => {
    setShowUpdate(true);
    setId(row._id);
    geCategoryData(row._id);
  };

  const handleClose = () => {
    setName('');
    setFeatured('');
    setShow(false);
    reset({
      name: "",
      featured: "",
      image: ""
    });
  }

  const handleCloseUpdate = () => {
    setUpdateName('');
    setUpdateFeatured('');
    setUpdatedImage('');
    setShowUpdate(false);
    reset1({
      name: "",
      featured: "",
      image: ""
    });
  }

  const getServices = () => {
    Axios.get('api/getAllCategories')
      .then((response) => {
        setData(response?.data?.data);
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
        console.log(err);
      });

  }

  useEffect(() => {
    getServices();
  }, []);





  const tableData = {
    columns: [
      {
        name: <th className='fs-6 ' >#</th>,
        cell: (row, index) => index + 1,
        width: '150px',
      },
      {
        name: <th className='fs-6 '>Name</th>,
        selector: (row) => row.name,
        width: '200px',
      },

      {
        name: <th className='fs-6'>Image</th>,
        selector: (row) => (
          <img width="60px" height="60px" className='p-2' src={`/assets/category/${row.image}`} alt="Portfolio Image" />
        ),
        width: '200px',
      },

      // 
      {
        name: <th className='fs-6'>Featured</th>,
        selector: (row) => (
          <span>{row.featured === 0 ? 'Yes' : 'No'}</span>
        ),
        width: '200px',
      },

      // {
      //   name: <th className='fs-6 '>status</th>,
      //   selector: 'cta',
      //   width: '200px',
      // },
      {
        name: <th className='fs-6'>Action</th>,
        selector: (row) => (
          <div className='d-flex' style={{ gap: '10px' }}>

            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }} onClick={() => handleShowUpdate(row)}>
              <FaEdit />
            </Button>
            {/* /${row._id} */}
            <Button className='_delete_btn_ bg-danger text-white' onClick={() => confirmDelete(row._id)} variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}><FaTrashAlt /></Button>
          </div>
        ),
        right: true,


      },
    ],
  };

  const { register, handleSubmit, reset, formState: { errors: errors } } = useForm();
  const { register: register1, handleSubmit: handleSubmit1, reset: reset1, formState: { errors: errors1 } } = useForm();


  const onSubmit = () => {
    setPending(true);

    // const formData = new FormData();
    // formData.append("image", image)
    Axios.post('/api/addCategory', {
      name: name,
      image: image,
      featured: featured
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setPending(false);
          handleClose();
          notify('Category Added Successfully');
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        }
      })
      .catch((err) => {
        setPending(false);
        //   addPortfolioError();
        console.error(err);
      });
  }

  const geCategoryData = (_id) => {

    Axios.get(`api/getSingleCategory/${_id}`)
      .then((response) => {
        const category = response?.data?.data[0];
        if (category) {
          setId(category._id);
          setUpdateName(category.name);
          setUpdateFeatured(category.featured);
          setUpdateImage(category.image);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onUpdate = () => {
    setPending(true);

    const formData = new FormData();
    formData.append("updatedImage", updatedImage);


    Axios.put(`api/updateCategory`, {
      name: updateName,
      featured: updateFeatured,
      image: updatedImage,
      id: id

    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((response) => {
        if (response.status === 200) {
          notify('Category Update Successfully');
          setUpdateName('');
          setUpdateFeatured('');
          setUpdatedImage('');
          handleCloseUpdate();
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        }
      })
      .catch((err) => {
        notifyErr('Something went wrong');
        console.error(err);
      });

  };



  const handleDelete = async (id) => {
    try {
      const response = await Axios.delete(`/api/DeleteCategory/${id}`);
      if (response.status === 200) {
        setTimeout(() => {
          window.location.reload(true);
        }, 2000);
      }
      Swal.fire({
        title: 'Deleted!',
        text: 'Your data has been deleted.',
        icon: 'success',
        confirmButtonColor: '#b98d58',
      });
    } catch (error) {
      console.error(error);

      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the data.',
        icon: 'error',
      });
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: 'Are you sure to Delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b98d58',
      cancelButtonColor: 'grey',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {

      if (result.value) {
        console.log(result)

        handleDelete(id);
      }
    });
  };



  return (
    <>
      <div className='category-page'>
        <Helmet>
          <title>Category</title>
        </Helmet>

        <section>
          <ToastContainer />
          <section className='category-section'>
            <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
              <div className="">
                <span className='fw-bold'>Categories</span>
              </div>
              <div className="">
                <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#b98d58', borderRadius: '0' }} onClick={handleShow}>
                  Add Category
                </Button>
              </div>
            </div>
          </section>
        </section>

        <section className=' mt-2'>
          <Card >
            <div className="main ">
              <DataTable data={data} columns={tableData.columns} />
            </div>
          </Card>

        </section>
      </div>

      {/* Category Add Modal */}
      <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
        <ToastContainer />
        <Modal.Header closeButton>

          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Form className='row m-2' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Modal.Body>
            <div className='row'>
              <Form.Group className="mt-0 ">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={name}
                  {...register("name", {
                    required: true,
                    onChange: (e) => setName(e.target.value)
                  })}
                />

                {errors.name && (
                  <div className='errMsg text-danger'>Name is required</div>
                )}
              </Form.Group>

              <Form.Group className="mt-2">
                <Form.Label htmlFor="Featured">Featured</Form.Label>
                <Form.Select
                  id="Featured"
                  aria-label="Default select example"
                  value={featured}
                  {...register("Featured", {
                    required: true,

                    onChange: (e) => setFeatured(e.target.value)
                  })}


                >
                  <option value="" disabled>Select Featured</option>
                  <option value="0" >Yes</option>
                  <option value="1">No</option>

                </Form.Select>
                {errors.Featured && (
                  <div className='errMsg text-danger'>Featured is required</div>
                )}
              </Form.Group>


              <Form.Group
                className="mt-2"
                controlId="exampleForm.ControlTextarea1"
              >

                <div className='row'>
                  <div className='col-lg-12'>
                    <Form.Label>Image</Form.Label>
                    <Form.Control type="file" name="file"
                      {...register("Image", {
                        required: false,
                        onChange: (e) => setImage(e.target.files[0])
                      })}
                    />

                    {errors.Image && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-lg-12 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {image == "" ?

                        <img src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULSPiQKGEcCtCxrkr4t9Ub8U-Jwzv3kXu2RMOzQoihg&s`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                        :
                        <img src={URL.createObjectURL(image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>


            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#3e3d52' }} >
              Add
            </Button>
            <Button variant="secondary" onClick={handleClose} >
              Close
            </Button>

          </Modal.Footer>
        </Form>
      </Modal>


      {/* update */}
      <Modal show={showUpdate} onHide={handleCloseUpdate} style={{ zIndex: '99999' }}>
        <ToastContainer />
        <Modal.Header closeButton>
          <Modal.Title>Update Category</Modal.Title>
        </Modal.Header>
        <Form className='row m-2' onSubmit={handleSubmit1(onUpdate)} autoComplete="off">
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control

                type="text"
                id="update_name"
                placeholder="Name"
                value={updateName}
                {...register1("update_name", {
                  required: false,
                  onChange: (e) => setUpdateName(e.target.value)
                })}
              />
              {errors1.update_name && (
                <div className='errMsg text-danger'>Please Enter Name</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3 mt-2">
              <Form.Label htmlFor="Featured">Featured</Form.Label>
              <Form.Select
                id="Featured"
                aria-label="Default select example"
                value={updateFeatured}
                {...register("Featured", {
                  required: true,

                  onChange: (e) => setUpdateFeatured(e.target.value)
                })}


              >
                <option value="" disabled>Select Featured</option>
                <option value="1" >Yes</option>
                <option value="0">No</option>

              </Form.Select>
              {errors.Featured && (
                <div className='errMsg text-danger'>Featured is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <div className='row'>
                <div className='col-lg-12'>
                  <Form.Label>Image</Form.Label>
                  <Form.Control type="file" id="file"
                    {...register1("Image", {
                      required: false,
                      onChange: (e) => setUpdatedImage(e.target.files[0])
                    })}
                  />

                  {errors1.Image && (
                    <div className='errMsg text-danger'>Please Select Image</div>
                  )}

                </div>

                <div className='col-lg-12 text-center mb-3 mt-3'>
                  <Form.Label>Image Preview</Form.Label>
                  <div className='p-3'>

                    {updatedImage == "" ?

                      <img src={`/assets/category/${updateImage}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                      :
                      <img src={URL.createObjectURL(updatedImage)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                    }
                  </div>
                </div>
              </div>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="contained" className='text-white' style={{ backgroundColor: '#3e3d52' }}  >
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>

          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default Category