import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import DataTable from '../../datatable/DataTable';
import { Axios } from '../../components/Axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';


const Cards = () => {

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);

    const getData = async () => {
        try {

            const productsData = await Axios.get('/api/getAllCard');
            let products = productsData.data.data;
            setData(products);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Page name</th>,
                selector: (row) => (
                    <span>{row.type == 1 ? 'Home Page' : row.type == 2 ? 'About Page' : row.type == 3 ? 'Shop Page' : '-'}</span>
                ),
                width: '200px',
            },
            {
                name: <th className='fs-6 '>Title</th>,
                selector: (row) => row.title,
                width: '400px',
            },

            {
                name: <th className='fs-6 '>Button</th>,
                selector: (row) => row.btn_name,
                width: '400px',
            },

            {
                name: <th className='fs-6'>Front Image</th>,
                selector: (row) => (
                    <img width="60px" height="60px" className='p-2' src={`/assets/cards/${row.front_image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },

            {
                name: <th className='fs-6'>Back Image</th>,
                selector: (row) => (
                    row.back_image != ' ' && <img width="60px" height="60px" className='p-2' src={`/assets/cards/${row.back_image}`} alt="Portfolio Image" />
                ),
                width: '200px',
            },



            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Link to={`/cards/${row._id}`}>
                            <Button className='Add_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}>
                                <FaEdit />
                            </Button>
                        </Link>
                    </div>
                ),
                right: true,


            },
        ],
    };

    return (
        <>
            <div className='card-page'>
                <Helmet>
                    <title>Cards</title>
                </Helmet>
                <section>
                    <ToastContainer />
                    <section className='card-section'>
                        <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                            <div className="">
                                <span className='fw-bold'>Cards</span>
                            </div>

                        </div>
                    </section>
                </section>
                <section className=' mt-2'>
                    <Card >
                        <div className="main ">
                            <DataTable data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>
            </div>
        </>
    )
}

export default Cards