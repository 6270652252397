import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Axios } from '../../components/Axios';
import { notify, notifyErr } from '../../components/common/notify';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaTrashAlt } from 'react-icons/fa'

const UpdateProduct = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [pending, setPending] = useState(true);
  const [prevImages, setPrevImages] = useState([])
  const [images, setImages] = useState('');
  const [longDes, setLongDes] = useState('')
  const [formData, setFormData] = useState({
    id: '',
    category_id: '',
    name: '',
    description: '',
    seo_title: '',
    seo_description: '',
    seo_canonical: '',
    seo_robot: '',
    seo_script: '',
    seo_feature_image: '',
    featured: '',
    price: '',
    long_description: ''
  });



  const getData = async () => {
    try {
      const categoryData = await Axios.get(`/api/getCategory`);
      let category = categoryData.data.data;
      setCategories(category);
      setPending(false);
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }

    try {
      const productData = await Axios.get(`/api/getSingleProduct/${id}`);
      let product = productData.data.data[0];
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['id']: product._id,
        ['category_id']: product.category_id,
        ['name']: product.name,
        ['description']: product.description,
        ['long_description']: product.long_description,
        ['price']: product.price,
        ['featured']: product.featured,
        ['seo_title']: product.seo_title,
        ['seo_description']: product.seo_description,
        ['seo_canonical']: product.seo_canonical,
        ['seo_robot']: product.seo_robot,
        ['seo_script']: product.seo_script,
        ['seo_feature_image']: product.seo_feature_image,
      }));
      // setId(product._id)
      setLongDes(product.long_description)
      setPrevImages(product.image)

    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);


  const handleImageChange = (e) => {
    const files = e.target.files;
    const imagesArray = [];

    for (let index = 0; index < files.length; index++) {
      const imageFile = files[index];
      imagesArray.push(imageFile);
    }

    // // Set the "image" field in the formData state to the array of images
    // setFormData({ ...formData, image: imagesArray });
    setImages(imagesArray);
  };



  const { register, handleSubmit, formState: { errors } } = useForm();
  // const onSubmit = (data) => {
  //   console.log(data)
  // }

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };


  useEffect(() => {
    handleInputChange('long_description', longDes);
  }, [longDes])


  const onSubmit = async () => {

    try {
      const response = await Axios.put('/api/updateProduct', formData);
      if (response.status === 200 || 201) {
        notify('Product Update Successfully');
        setTimeout(() => {
          window.location.reload(false)
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }

  }


  const onUpdate = async () => {
    const imagesToUpload = images;
    const formDataToPost = new FormData();
    formDataToPost.append('id', id);

    for (let index = 0; index < imagesToUpload.length; index++) {
      formDataToPost.append('image', imagesToUpload[index]);
    }

    try {
      const response = await Axios.put('/api/updateProductImage', formDataToPost);
      if (response.status === 200 || 201) {
        notify('Product Update Successfully');
        setTimeout(() => {
          window.location.reload(false)
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }

  }


  const handleDelete = async (item) => {
    try {
      const response = await Axios.delete(`/api/DeleteProductImage/${id}/${item}`);
      if (response.status === 200 || 201) {
        notify('Product Update Successfully');
        setTimeout(() => {
          window.location.reload(false)
        }, 1500);
      }
    } catch (error) {
      notifyErr('Something went wrong')
      console.error(error);
    }
  }



  return (
    <>
      <div className='update-page'>
        <Helmet>
          <title>Update Product</title>
        </Helmet>
        <section className="product-add">
          <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
            <div className="">
              <span className='fw-bold'>Update Product</span>
            </div>

          </div>
        </section>
        <ToastContainer />
        <section class="card  cardSectionBorder mt-3">
          <div className='m-2'>
            <Form className='row' onSubmit={handleSubmit(onSubmit)}>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label htmlFor="category">Category</Form.Label>
                <Form.Select
                  id="category"
                  aria-label="Default select example"
                  value={formData?.category_id || ''}

                  {...register("Category", {
                    required: false,
                    onChange: (e) => handleInputChange('category_id', e.target.value)
                  })}
                >
                  <option value="" disabled>Select Category</option>
                  {categories?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>

                {errors.Category && (
                  <div className='errMsg text-danger'>Category is required</div>
                )}
              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={formData?.name || ''}
                  {...register("name", {
                    required: false,
                    onChange: (e) => handleInputChange('name', e.target.value)
                  })}
                />

                {errors.name && (
                  <div className='errMsg text-danger'>Name is required</div>
                )}
              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label htmlFor="Featured">Featured</Form.Label>
                <Form.Select
                  id="Featured"
                  aria-label="Default select example"
                  value={formData?.featured || ''}
                  {...register("Featured", {
                    required: false,
                    onChange: (e) => handleInputChange('featured', e.target.value)
                  })}


                >
                  <option value="" disabled>Select Featured</option>
                  <option value="1" >No</option>
                  <option value="0">Yes</option>

                </Form.Select>
                {errors.Featured && (
                  <div className='errMsg text-danger'>Featured is required</div>
                )}
              </Form.Group>


              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Name"
                  value={formData?.price || ''}
                  {...register("price", {
                    required: false,
                    onChange: (e) => handleInputChange('price', e.target.value)
                  })}
                />

                {errors.price && (
                  <div className='errMsg text-danger'>price is required</div>
                )}
              </Form.Group>


              <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Description</Form.Label>
                <Form.Control className="" rows="5" as="textarea"
                  value={formData?.description || ''}
                  {...register("description", {
                    required: false,
                    onChange: (e) => handleInputChange('description', e.target.value)
                  })}
                />

                {errors.description && (
                  <div className='errMsg text-danger'>description is required</div>
                )}

              </Form.Group>

              <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>Long Description</Form.Label>
                <TextEditor data={longDes} setUpdatedContent={setLongDes} />
              </Form.Group>

              <div className="col-lg-12 mt-5">
                <span className=' fw-bold'>SEO Section</span>
              </div>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label>SEO Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="SEO Title"
                  value={formData?.seo_title || ''}
                  {...register("Seo_title", {
                    required: false,
                    onChange: (e) => handleInputChange('seo_title', e.target.value)
                  })}
                />

                {errors.Seo_title && (
                  <div className='errMsg text-danger'>Seo_title is required</div>
                )}
              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label>Canonical</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Canonical"
                  value={formData?.seo_canonical || ''}
                  onChange={(e) => handleInputChange('seo_canonical', e.target.value)}

                />

              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label htmlFor="Robot">Robot</Form.Label>
                <Form.Select
                  id="Robot"
                  aria-label="Default select example"
                  value={formData?.seo_robot || ''}
                  onChange={(e) => handleInputChange('seo_robot', e.target.value)}

                >
                  <option value=''>Select Robot</option>
                  <option value="index,follow" >Index,Follow</option>
                  <option value="index,no-follow">Index,No-Follow</option>
                  <option value="no-index,follow">No-Index,Follow</option>
                  <option value="no-index,no-follow">No-Index,No-Follow</option>
                </Form.Select>

              </Form.Group>

              <Form.Group className="mt-2 mb-3 col-6">
                <Form.Label>Script</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Script"
                  value={formData?.seo_script || ''}
                  onChange={(e) => handleInputChange('seo_script', e.target.value)}

                />

              </Form.Group>

              <Form.Group
                className="mb-3"
              >
                <Form.Label>Feature Image</Form.Label>
                <Form.Control type="url" name="url"
                  value={formData?.seo_feature_image || ''}
                  onChange={(e) => handleInputChange('seo_feature_image', e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-12 col-md-12 col-sm-12 col-12">
                <Form.Label>SEO Description</Form.Label>
                <Form.Control as="Textarea" placeholder="SEO Description"
                  value={formData?.seo_description || ''}
                  onChange={(e) => handleInputChange('seo_description', e.target.value)} />

              </Form.Group>

              <hr></hr>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                  Update Product
                </Button>
              </div>
            </Form>

          </div>


        </section >

        <section className='card cardSectionBorder mt-3 '>
          <div className='product-images m-2'>

            <Form.Group
              className="mt-2"
            >
              <div className='row m-0'>
                <div className='col-lg-12 mb-3 p-3'>
                  <Form.Label>Product Images</Form.Label>
                  <div className='m-2 text-center'>
                    <ImageList cols={6}>
                      {prevImages?.map((item, index) => (
                        <ImageListItem key={index} style={{ border: '2px solid #2e2d3c' }} className='position-relative'>
                          <Button className='text-white bg-danger border-0 position-absolute'
                            onClick={() => handleDelete(item)}
                            style={{ borderRadius: '0', right: '0', top: '0' }}>
                            <FaTrashAlt />
                          </Button>
                          <img
                            srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            // src={`${URL.createObjectURL(item)}?w=164&h=164&fit=crop&auto=format`}
                            src={`/assets/product/${item}`}
                            alt={`image-${index}`}
                            loading="lazy" className='object-fit-contain'
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </div>
                </div>

                <div className='text-left col-lg-6 col-12'>
                  <Form.Label>Add Image</Form.Label>
                  <Form.Control type="file" name="file" multiple
                    onChange={(e) => handleImageChange(e)}
                  />

                  {errors.Image && (
                    <div className='errMsg text-danger'>Please Select Image</div>
                  )}

                </div>
                <div className='col-12 mt-3'>
                  <div className='d-flex justify-content-start gap-3 align-items-center mb-3'>
                    <Form.Label>Image Preview</Form.Label>
                    {images?.length > 0 &&
                      <Button className='text-white w-20 bg-danger' variant='contained'
                        onClick={() => setImages('')}
                      >
                        <FaTrashAlt /> Clear
                      </Button>}
                  </div>

                  {images?.length > 0 &&
                    <ImageList cols={6}>
                      {images?.map((item, index) => (
                        <ImageListItem key={index} style={{ border: '2px solid #2e2d3c' }}>
                          <img
                            srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            // src={`${URL.createObjectURL(item)}?w=164&h=164&fit=crop&auto=format`}
                            src={URL.createObjectURL(item)}
                            alt={`image-${index}`}
                            loading="lazy" className='object-fit-contain'
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  }
                </div>
              </div>

            </Form.Group>

            <hr></hr>
            <div className='btn-d d-flex justify-content-center'>
              <Button type='button' className='text-white w-20' variant='contained'
                onClick={onUpdate}
                style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                Add Image
              </Button>
            </div>

          </div>
        </section>

      </div >
    </>
  )
}

export default UpdateProduct