import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { Axios } from '../components/Axios';
import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { notify, notifyErr } from '../components/common/notify';



const About = () => {


    const [experienceImg, setExperienceImg] = useState('');
    const [experience, setExperience] = useState({
        title: '',
        content: '',
        image: '',
    });
    const [advantageImg, setAdvantageImg] = useState('');
    const [advantage, setAdvantage] = useState({
        title: '',
        content: '',
        image: '',
    });
    const [history, setHistory] = useState('');


    const getData = async () => {
        try {

            const experienceData = await Axios.get('/api/getAboutHistory/1');
            let experiences = experienceData.data.data[0];
            setExperience((prevFormData) => ({
                ...prevFormData,
                ['id']: experiences._id,
                ['title']: experiences.title,
                ['content']: experiences.content,
            }));
            setExperienceImg(experiences.image)
            // const productsData = await Axios.get('/api/getAllCards/2');
            // let products = productsData.data.data;
            // setData(products);

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
        try {

            const advantagesData = await Axios.get('/api/getAboutHistory/2');
            let advantages = advantagesData.data.data[0];
            setAdvantage((prevFormData) => ({
                ...prevFormData,
                ['id']: advantages._id,
                ['title']: advantages.title,
                ['content']: advantages.content,
            }));
            setAdvantageImg(advantages.image)

        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
        try {
            const historyData = await Axios.get('/api/getAboutHistory/3');
            let histories = historyData.data.data[0];
            setHistory(histories);


        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);



    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 } } = useForm();

    const handleInputChange = (fieldName, value) => {
        setExperience((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };


    const handleInputChangeAdvantages = (fieldName, value) => {
        setAdvantage((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };



    const onSubmit = async () => {

        try {
            const response = await Axios.put('/api/updateAboutHistory', experience,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('updated Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }

    const onSubmitAdvantages = async () => {
        try {
            const response = await Axios.put('/api/updateAboutHistory', advantage,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('updated Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }




    return (
        <>
            <div className='About-page'>
                <Helmet>
                    <title>About</title>
                </Helmet>
                <section>
                    <ToastContainer />
                    <section className='product-section'>
                        <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                            <div className="">
                                <span className='fw-bold'>About</span>
                            </div>

                        </div>
                    </section>
                </section>
                <section className=' mt-2'>
                    <Card >
                        <div className="main ">

                            <div className=' m-2'>
                                <h4 className='mt-2 fw-bold'>Experience</h4>
                                <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mt-2 mb-3 col-6">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            value={experience?.title || ''}
                                            {...register("title", {
                                                required: false,
                                                onChange: (e) => handleInputChange('title', e.target.value)
                                            })}
                                        />

                                        {errors.title && (
                                            <div className='errMsg text-danger'>Title is required</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <Form.Label>Content</Form.Label>
                                        <Form.Control className="" rows="5" as="textarea"
                                            value={experience?.content || ''}
                                            {...register("content", {
                                                required: false,
                                                onChange: (e) => handleInputChange('content', e.target.value)
                                            })}
                                        />

                                        {errors.content && (
                                            <div className='errMsg text-danger'>content is required</div>
                                        )}

                                    </Form.Group>

                                    <Form.Group
                                        className="mt-2"
                                    >

                                        <div className='row'>
                                            <div className='col-6'>
                                                <Form.Label>Image</Form.Label>
                                                <Form.Control type="file" name="file"
                                                    {...register("image", {
                                                        required: false,
                                                        onChange: (e) => handleInputChange('image', e.target.files[0])
                                                    })}
                                                />

                                                {errors.image && (
                                                    <div className='errMsg text-danger'>Please Select Image</div>
                                                )}

                                            </div>

                                            <div className='col-6 text-center mb-3 mt-3'>
                                                <Form.Label>Image Preview</Form.Label>
                                                <div className='p-3'>

                                                    {experience?.image == "" ?

                                                        <img src={`/assets/about/${experienceImg}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                        :
                                                        <img src={URL.createObjectURL(experience?.image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                    </Form.Group>



                                    <hr></hr>
                                    <div className='btn-d d-flex justify-content-center'>
                                        <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                                            Update
                                        </Button>
                                    </div>
                                </Form>


                            </div>
                        </div>
                    </Card>

                </section>

                <section className=' mt-2'>
                    <Card >
                        <div className="main ">

                            <div className=' m-2'>
                                <h4 className='mt-2 fw-bold'>Advantages</h4>
                                <Form className='row' onSubmit={handleSubmit1(onSubmitAdvantages)}>
                                    <Form.Group className="mt-2 mb-3 col-6">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            value={advantage?.title || ''}
                                            {...register1("title", {
                                                required: false,
                                                onChange: (e) => handleInputChangeAdvantages('title', e.target.value)
                                            })}
                                        />

                                        {errors.title && (
                                            <div className='errMsg text-danger'>Title is required</div>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <Form.Label>Content</Form.Label>
                                        <Form.Control className="" rows="5" as="textarea"
                                            value={advantage?.content || ''}
                                            {...register1("content", {
                                                required: false,
                                                onChange: (e) => handleInputChangeAdvantages('content', e.target.value)
                                            })}
                                        />

                                        {errors.content && (
                                            <div className='errMsg text-danger'>content is required</div>
                                        )}

                                    </Form.Group>

                                    <Form.Group
                                        className="mt-2"
                                    >

                                        <div className='row'>
                                            <div className='col-6'>
                                                <Form.Label>Image</Form.Label>
                                                <Form.Control type="file" name="file"
                                                    {...register1("image", {
                                                        required: false,
                                                        onChange: (e) => handleInputChangeAdvantages('image', e.target.files[0])
                                                    })}
                                                />

                                                {errors.image && (
                                                    <div className='errMsg text-danger'>Please Select Image</div>
                                                )}

                                            </div>

                                            <div className='col-6 text-center mb-3 mt-3'>
                                                <Form.Label>Image Preview</Form.Label>
                                                <div className='p-3'>

                                                    {advantage?.image == "" ?

                                                        <img src={`/assets/about/${advantageImg}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                        :
                                                        <img src={URL.createObjectURL(advantage?.image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                    </Form.Group>



                                    <hr></hr>
                                    <div className='btn-d d-flex justify-content-center'>
                                        <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                                            Update
                                        </Button>
                                    </div>
                                </Form>


                            </div>
                        </div>
                    </Card>

                </section>

            </div>
        </>
    )
}

export default About