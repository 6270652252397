import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import OutlinedInput from '@mui/material/OutlinedInput';
import { Axios } from '../components/Axios';
import { notify } from '../components/common/notify';


const Setting = () => {

  {/* api/getSetting */ }

  const [pending, setPending] = useState(true);
  const [frontImage, setFrontImage] = useState('');
  const [id, setId] = useState('');
  //   {
  //   product_ids: [],
  //   product_name: []
  // }
  const [formData, setFormData] = useState({
    id: '',
    email: '',
    logo: '',
    phone: '',
    address: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    mapUrl: '',
    smtp_email: '',
    smtp_password: ''
  })


  const getData = () => {
    Axios.get('/api/getSetting/1')
      .then((response) => {
        const settingData = response?.data?.data[0];
        if (settingData) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ['id']: settingData._id,
            ['email']: settingData.email,
            ['mapUrl']: settingData.mapUrl,
            ['address']: settingData.address,
            ['phone']: settingData.phone,
            ['facebookUrl']: settingData.facebookUrl,
            ['instagramUrl']: settingData.instagramUrl,
            ['twitterUrl']: settingData.twitterUrl,
            ['smtp_email']: settingData.smtp_email,
            ['smtp_password']: settingData.smtp_password,

          }));
          setFrontImage(settingData.logo)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);


  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };





  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async () => {
    try {
      const response = await Axios.put(`/api/updateSetting`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200 || 201) {
        notify();
        setTimeout(() => {
          window.location.reload(false)
        }, 1500);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <>
      <div>
        <Helmet>
          <title>Setting</title>
        </Helmet>
        <section className="inquire_update">
          <div className="row">
            <ToastContainer />
            <section className='inq-section'>
              <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                <div className="">
                  <span className='fw-bold'>Setting</span>
                </div>

              </div>
            </section>
          </div>
        </section>

        <section class="card cardSectionBorder mt-3">

          <div className=' m-2'>
            <Form className='row m-0' onSubmit={handleSubmit(onSubmit)} >


              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Email</Form.Label>
                <Form.Control className="" type="email" placeholder="Email" value={formData.email}
                  {...register("Email", {
                    required: false,
                    onChange: (e) => handleInputChange('email', e.target.value)
                  })}
                />

                {errors.Email && (
                  <div className='errMsg text-danger'>Email is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="Phone"
                  value={formData.phone}
                  {...register("phone", {
                    required: false,
                    onChange: (e) => handleInputChange('phone', e.target.value)
                  })}
                />

                {errors.phone && (
                  <div className='errMsg text-danger'>Phone is Required</div>
                )}
              </Form.Group>


              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-1">
                <Form.Label>Address</Form.Label>
                <Form.Control className="" rows="5" as="textarea" placeholder="address" value={formData.address}
                  {...register("address", {
                    required: false,
                    onChange: (e) => handleInputChange('address', e.target.value)
                  })}
                />

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Map Url</Form.Label>
                <Form.Control className="" type="text" placeholder="mapUrl" value={formData.mapUrl}
                  {...register("mapUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('mapUrl', e.target.value)
                  })}
                />

                {errors.mapUrl && (
                  <div className='errMsg text-danger'>mapUrl is required</div>
                )}

              </Form.Group>

              <Form.Group
                className="mt-2"
              >

                <div className='row'>
                  <div className='col-6'>
                    <Form.Label>Logo Image</Form.Label>
                    <Form.Control type="file" name="file"
                      {...register("logo", {
                        required: false,
                        onChange: (e) => handleInputChange('logo', e.target.files[0])
                      })}
                    />

                    {errors.logo && (
                      <div className='errMsg text-danger'>Please Select Image</div>
                    )}

                  </div>

                  <div className='col-6 text-center mb-3 mt-3'>
                    <Form.Label>Image Preview</Form.Label>
                    <div className='p-3'>

                      {formData?.logo == "" ?

                        <img src={`/assets/${frontImage}`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                        :
                        <img src={URL.createObjectURL(formData?.logo)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                      }
                    </div>

                  </div>
                </div>

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Facebook Url</Form.Label>
                <Form.Control className="" type="text" placeholder="Name" value={formData.facebookUrl}
                  {...register("facebookUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('facebookUrl', e.target.value)
                  })}
                />

                {errors.facebookUrl && (
                  <div className='errMsg text-danger'>facebook Url is required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Instagram Url</Form.Label>
                <Form.Control className="" type="text" placeholder="Instagram" value={formData.instagramUrl}
                  {...register("Instagram", {
                    required: false,
                    onChange: (e) => handleInputChange('Instagram', e.target.value)
                  })}
                />

                {errors.Instagram && (
                  <div className='errMsg text-danger'>instagram Url is required</div>
                )}

              </Form.Group>


              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>Twitter Url</Form.Label>
                <Form.Control className="" type="text" placeholder="twitterUrl" value={formData.twitterUrl}
                  {...register("twitterUrl", {
                    required: false,
                    onChange: (e) => handleInputChange('twitterUrl', e.target.value)
                  })}
                />

                {errors.Name && (
                  <div className='errMsg text-danger'>twitter Url is required</div>
                )}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12"></Form.Group>
              <Form.Group className="mb-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Label>SMTP Email</Form.Label>
                <Form.Control className="" type="email" placeholder="SMTP Email" value={formData.smtp_email}
                  {...register("smtp_email", {
                    required: false,
                    onChange: (e) => handleInputChange('smtp_email', e.target.value)
                  })}
                />

                {errors.Email && (
                  <div className='errMsg text-danger'>smtp email is Required</div>
                )}

              </Form.Group>

              <Form.Group className="mb-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Label>SMTP Password</Form.Label>
                <Form.Control type="password" placeholder="SMTP Password" value={formData.smtp_password} autoComplete='new-password'
                  {...register("smtp_password", {
                    required: false,
                    onChange: (e) => handleInputChange('smtp_password', e.target.value)
                  })}
                />

                {errors.smtp_password && (
                  <div className='errMsg text-danger'>smtp password is Required</div>
                )}

              </Form.Group>
              <div className='btn-d d-flex justify-content-center'>
                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                  Update
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </>
  )
}

export default Setting