import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from '../datatable/DataTable';
import Card from 'react-bootstrap/Card';
import { Axios } from '../components/Axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import Swal from "sweetalert2";
import { notify, notifyErr } from '../components/common/notify';
const Faqs = () => {
    // /api/getContactFaq

    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    // add category
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);


    const handleClose = () => {
        setQuestion('');
        setAnswer('');
        setShow(false);
        reset({
            question: "",
            answer: "",
        });
    }

    const getData = () => {
        Axios.get('/api/getContactFaq').then((response) => {
            setData(response?.data?.data);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getData();
    }, []);


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            {
                name: <th className='fs-6 '>Question</th>,
                selector: (row) => row.question,
                width: '400px',
            },

            {
                name: <th className='fs-6 '>Answer</th>,
                selector: (row) => row.answer,
                width: '400px',
            },

            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Button className='_delete_btn_ bg-danger text-white' variant='contained' onClick={() => confirmDelete(row._id)} style={{ float: 'right', backgroundColor: '#3e3d52' }}><FaTrashAlt /></Button>
                    </div>
                ),
                right: true,


            },
        ],
    };


    const { register, handleSubmit, reset, formState: { errors: errors } } = useForm();

    const onSubmit = async () => {
        setPending(true);
        try {
            const response = await Axios.post('/api/addContact', {
                question: question,
                answer: answer
            },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
            if (response.status === 200 || 201) {
                notify('Faq added Successfully');
                handleClose();
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }

    const handleDelete = async (id) => {
        try {
            const response = await Axios.delete(`/api/deleteContactFaq/${id}`);
            if (response.status === 200) {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
            Swal.fire({
                title: 'Deleted!',
                text: 'Your data has been deleted.',
                icon: 'success',
                confirmButtonColor: '#b98d58',
            });
        } catch (error) {
            console.error(error);

            Swal.fire({
                title: 'Error',
                text: 'An error occurred while deleting the data.',
                icon: 'error',
            });
        }
    };



    const confirmDelete = (id) => {
        Swal.fire({
            title: 'Are you sure to Delete?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#b98d58',
            cancelButtonColor: 'grey',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {

            if (result.value) {
                console.log(result)
                handleDelete(id);
            }
        });
    };


    return (
        <>
            <div className='faqs-page'>
                <Helmet>
                    <title>Faqs</title>
                </Helmet>
                <section>
                    <ToastContainer />
                    <section className='faqs-section'>
                        <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                            <div className="">
                                <span className='fw-bold'>Faqs</span>
                            </div>
                            <div className="">
                                <Button className='Add_btn_ text-white' variant='contained'
                                    onClick={handleShow}
                                    style={{ float: 'right', backgroundColor: '#b98d58', borderRadius: '0' }}  >
                                    Add Faqs
                                </Button>
                            </div>
                        </div>
                    </section>
                </section>
                <section className=' mt-2'>
                    <Card >
                        <div className="main ">
                            <DataTable data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>
            </div>


            <Modal show={show} onHide={handleClose} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Add Faq</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>
                        <div className='row'>
                            <Form.Group className="mt-0">
                                <Form.Label>Question</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="question"
                                    value={question}
                                    {...register("question", {
                                        required: true,
                                        onChange: (e) => setQuestion(e.target.value)
                                    })}
                                />

                                {errors.question && (
                                    <div className='errMsg text-danger'>question is required</div>
                                )}
                            </Form.Group>


                            <Form.Group className="mb-3">
                                <Form.Label>Answer</Form.Label>
                                <Form.Control className="" rows="5" as="textarea" placeholder="answer" value={answer}
                                    {...register("answer", {
                                        required: true,
                                        onChange: (e) => setAnswer(e.target.value)
                                    })}
                                />

                            </Form.Group>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#3e3d52' }} >
                            Add
                        </Button>
                        <Button variant="secondary" onClick={handleClose} >
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>


        </>
    )
}

export default Faqs