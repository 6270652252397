import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { notify, notifyErr } from '../../components/common/notify';
import { Button } from 'react-bootstrap';
import { Axios } from '../../components/Axios';
import { ToastContainer } from 'react-toastify';


const EnquiryModel = ({ id, show, setShowUpdate }) => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        inquiry: '',
        phone: '',
        category_id: '',
        subcategory_id: '',
        product_id: '',
        reply: ''

    })


    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setFormData({
            name: '',
            email: '',
            inquiry: '',
            phone: '',
            category_id: '',
            subcategory_id: '',
            product_id: '',
            reply: ''
        })
        reset({
            name: '',
            email: '',
            inquiry: '',
            phone: '',
            category_id: '',
            subcategory_id: '',
            product_id: '',
            reply: ''

        });
    }


    const getSingleEnq = () => {
        Axios.get(`/api/getSingleInquiry/${id}`)
            .then((response) => {
                const inquiryData = response?.data?.data[0];
                if (inquiryData) {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        ['name']: inquiryData.name,
                        ['email']: inquiryData.email,
                        ['inquiry']: inquiryData.inquiry,
                        ['product_id']: inquiryData.product_id,
                        ['phone']: inquiryData.phone,
                    }));

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getSingleEnq();
    }, [id, show]);


    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };


    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async () => {
        try {
            const response = await Axios.put('api/updateInquiry', {
                id: id,
                reply: formData.reply
            });
            if (response.status === 200 || 201) {
                notify('Reply Sent Successfully');
                setTimeout(() => {
                    window.location.reload(false)
                }, 1500);
            }
        } catch (error) {
            console.error('Error:', error);
            notifyErr('Something went wrong');
        }
    }

    return (
        <>
            {/* Category Add Modal */}
            <Modal show={show} onHide={handleCloseUpdate} className='' style={{ zIndex: '99999' }}>
                <ToastContainer />
                <Modal.Header closeButton>

                    <Modal.Title>Enquiry Reply</Modal.Title>
                </Modal.Header>
                <Form className='row m-2' onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Modal.Body>

                        <Form.Group className=" mb-3">

                            <Form.Control className="" type="text" placeholder="Name" id="username" value={formData.name} disabled
                                {...register("Name", {
                                    required: false,
                                    onChange: (e) => handleInputChange('name', e.target.value)
                                })}
                            />

                            {errors.Name && (
                                <div className='errMsg text-danger'>Name is required</div>
                            )}

                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control disabled
                                className=""
                                type="tel"
                                placeholder="Phone"
                                value={formData.phone}
                                {...register("phone", {
                                    required: false,
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "Invalid phone number"
                                    },
                                    onChange: (e) => handleInputChange('phone', e.target.value)
                                })}
                            />

                            {errors.phone && (
                                <div className='errMsg text-danger'>Phone is Required</div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">

                            <Form.Control className="" type="email" placeholder="Email" value={formData.email} disabled
                                {...register("Email", {
                                    required: false,
                                    onChange: (e) => handleInputChange('email', e.target.value)
                                })}
                            />

                            {errors.Email && (
                                <div className='errMsg text-danger'>Email is Required</div>
                            )}

                        </Form.Group>

                        <Form.Group className="mb-3"></Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control className="" rows="5" as="textarea" placeholder="Products" value={formData.product_id} disabled
                                {...register("Enquiry", {
                                    required: false,
                                    onChange: (e) => handleInputChange('product_id', e.target.value)
                                })}
                            />

                        </Form.Group>

                        <Form.Group className="mb-3">

                            <Form.Control className="" rows="5" as="textarea" placeholder="Message" value={formData.inquiry} disabled
                                {...register("Enquiry", {
                                    required: false,
                                    onChange: (e) => handleInputChange('inquiry', e.target.value)
                                })}
                            />

                            {errors.Enquiry && (
                                <div className='errMsg text-danger'>Email is Required</div>
                            )}

                        </Form.Group>


                        <Form.Group className="mb-3">

                            <Form.Control className="" rows="5" as="textarea" placeholder="Reply" value={formData.reply}
                                {...register("reply", {
                                    required: true,
                                    onChange: (e) => handleInputChange('reply', e.target.value)
                                })}
                            />

                            {errors.Enquiry && (
                                <div className='errMsg text-danger'>Email is Required</div>
                            )}

                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit' className='text-white' variant='contained' style={{ backgroundColor: '#3e3d52' }} >
                            Reply
                        </Button>
                        <Button variant="secondary" onClick={handleCloseUpdate} >
                            Close
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal >
        </>
    )
}

export default EnquiryModel