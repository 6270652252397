import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute'
import Enquiry from './pages/inquiry/Enquiry';
import DashboardLayout from './layouts/DashboardLayout';
import Category from './pages/Category';
import Product from './pages/product/Product';
import Blog from './pages/blog/Blog';
import BlogAdd from './pages/blog/BlogAdd';
import UpdateBlog from './pages/blog/UpdateBlog';
import Login from './pages/Login';
import AddProduct from './pages/product/AddProduct';
import UpdateProduct from './pages/product/UpdateProduct';
import Banner from './pages/banners/Banner';
import UpdateBanner from './pages/banners/UpdateBanner';
import About from './pages/About';
import Profile from './pages/Profile';
import Setting from './pages/Setting';
import Faqs from './pages/Faqs';
import Cards from './pages/cards/Cards';
import UpdateCard from './pages/cards/UpdateCard';
import History from './pages/history/History';
import AddHistory from './pages/history/AddHistory';
import UpdateHistory from './pages/history/UpdateHistory';



function App() {
  let authenticated = localStorage.getItem("auth");

  return (
    <BrowserRouter>
      <Routes>
        {authenticated ? (
          <Route path="/" element={<ProtectedRoute authenticated={authenticated} />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/category" element={<Category />} />
            <Route path="/product" element={<Product />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/update-product/:id" element={<UpdateProduct />} />
            <Route path="/inquiry" element={<Enquiry />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/update-banner/:id" element={<UpdateBanner />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/add-blog" element={<BlogAdd />} />
            <Route path="/update-blog/:id" element={<UpdateBlog />} />
            <Route path="/about" element={<About />} />
            <Route path="/history" element={<History />} />
            <Route path="/add-history" element={<AddHistory />} />
            <Route path="/update-history/:id" element={<UpdateHistory />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/cards/:id" element={<UpdateCard />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/setting" element={<Setting />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<ProtectedRoute authenticated={authenticated} />} />
            <Route path="/" element={<Login />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
