import { AiFillDashboard, AiOutlineForm } from 'react-icons/ai';
import { IoImageSharp } from 'react-icons/io5';
import { FaInfo, FaMicroblog, FaProductHunt } from 'react-icons/fa';
import { RiPsychotherapyFill } from 'react-icons/ri';
import { MdFmdBad, MdContactPhone, MdGroups2, MdMarkEmailUnread } from 'react-icons/md';
import { SiHypothesis, SiGnuprivacyguard } from 'react-icons/si';
import { BiUserCheck } from 'react-icons/bi';
import { GiPlatform } from 'react-icons/gi'
import { BiSolidCategoryAlt } from 'react-icons/bi'


export const SidebarItems = [
    {
        title: "Dashboard",
        url: "/",
        icon: <AiFillDashboard />
    },
    {
        title: "Category",
        url: "/category",
        icon: <GiPlatform />
    },
    {
        title: "Product",
        url: "/product",
        icon: <BiSolidCategoryAlt />,
    },
    {
        title: "Banner",
        url: "/banner",
        icon: <IoImageSharp />,
    },
    {
        title: "Enquiry",
        url: "/inquiry",
        icon: <MdMarkEmailUnread />,
    },
    {
        title: "Cards",
        url: "/cards",
        icon: <FaProductHunt />,
    },
    {
        title: "About",
        url: "/about",
        icon: <FaInfo />,
    },
    {
        title: "History",
        url: "/history",
        icon: <MdFmdBad />,
    },
    {
        title: "Faqs",
        url: "/faqs",
        icon: <FaMicroblog />,
    },
    {
        title: "Blog",
        url: "/blog",
        icon: <FaMicroblog />,
    }

]