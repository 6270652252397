import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Datatables from '../../datatable/DataTable';
import { Axios } from '../../components/Axios';
import { FaTrashAlt, FaEdit, FaShare, FaEye } from 'react-icons/fa';

import EnquiryModel from './EnquiryModel';

const Enquiry = () => {
    const [id, setId] = useState('');
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [newData, setNewData] = useState([]);
    const [showUpdate, setShowUpdate] = useState(false);
    const [filterBy, setFilterBy] = useState('0')

    const handleShowUpdate = (row) => {
        setShowUpdate(true);
        setId(row._id);
    };


    const getInquiry = () => {
        Axios.get('api/getAllInquiry').then((response) => {
            // console.log(response?.data?.data);
            setData(response?.data?.data);
            setNewData(response?.data?.data)
            setPending(false);
        }).catch((err) => {
            setPending(false);
            console.log(err);
        })

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getInquiry();
    }, []);


    const tableData = {
        columns: [
            {
                name: <th className='fs-6 ' >#</th>,
                cell: (row, index) => index + 1,
                width: '100px',
            },
            // {
            //     name: <th className='fs-6 '>Inquiry</th>,
            //     selector: 'product_id',
            //     width: '150px',
            // },

            {
                name: <th className='fs-6 '>Name</th>,
                selector: (row) => row.name,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Phone</th>,
                selector: (row) => row.phone,
                width: '200px',
            },
            {
                name: <th className='fs-6'>Product Ids</th>,
                selector: (row) => row.product_id,
                width: '400px',
            },
            {
                name: <th className='fs-6'>Status</th>,
                cell: (row, index) => row.status == 0 ? <span className='bg-info text-white p-1 rounded'>Pending</span> : row.status == 1 ? <span className=' bg-success text-white p-1 rounded'>Complete</span> : '-',
                width: '220px',
              },
            {
                name: <th className='fs-6'>Action</th>,
                selector: (row) => (
                    <div className='d-flex' style={{ gap: '10px' }}>
                        <Button className='_delete_btn_ text-white' variant='contained' style={{ float: 'right', backgroundColor: '#3e3d52' }}
                            onClick={() => handleShowUpdate(row)}
                        ><FaShare /></Button>
                    </div>
                ),
                right: true,


            },
        ],
    };

    useEffect(() => {
        setData(newData?.filter(data => data.status == 0))
      }, [newData])
    
    
    
    
      const filterByStatus = (status) => {
        if (status == "") {
          setData(newData);
        } else {
          setData(newData?.filter(data => data.status == status));
        }
        setFilterBy(status)
      }
    

    return (
        <>
            <div className='product-page'>
                <Helmet>
                    <title>Inquiry</title>
                </Helmet>
                <section>
                    <ToastContainer />
                    <section className='inquiry-section'>
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center pt-5 pb-3 m-2">
                                <div className="">
                                    <span className='fw-bold'>Inquiry</span>
                                    <div>
                                        <strong >Status :-</strong>
                                        <select className="status select_status filter"
                                            value={filterBy}
                                            onChange={(e) => filterByStatus(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="0">Pending</option>
                                            <option value="1">Complete</option>


                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </section>
                <section className=' mt-2'>
                    <Card >
                        <div className="main ">
                            <Datatables data={data} columns={tableData.columns} />
                        </div>
                    </Card>

                </section>
            </div>

            <EnquiryModel id={id} show={showUpdate} setShowUpdate={setShowUpdate} />

        </>
    )
}

export default Enquiry