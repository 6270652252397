import React from 'react';
import LoginForm from '../components/LoginForm';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// import logo from '../assets/technoidol.svg'

// components

const Login = () => {

    const StyledContent = styled('div')(({ theme }) => ({
        maxWidth: 480,
        margin: 'auto',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(12, 0),
    }));

    return (
        <>
            {/* <Container maxWidth="sm"> */}
            <div className='w-100' style={{ background: '#f3ebe2' }}>
                <StyledContent>
                    <Paper elevation={8} className='login_paper'>
                        <div className='logo-d mx-auto mb-4'>
                            <img src={'	https://adminjewellery.reflomsolutions.com/assets/logo.png'} width={149} height={140} />
                        </div>
                        {/* <h1 style={{fontFamily:' Cormorant Infant,sans-serif', }}>Login</h1> */}
                        <LoginForm />
                    </Paper>
                </StyledContent>
            </div>
            {/* </Container> */}
        </>
    )
}

export default Login