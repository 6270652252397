import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react'
import TextEditor from '../../Editor/TextEditor';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { useForm, Controller } from 'react-hook-form';
import { Axios } from '../../components/Axios';
import { notify, notifyErr } from '../../components/common/notify';
import { useNavigate } from 'react-router-dom';

const AddHistory = () => {
    const [pending, setPending] = useState(true);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        year: '',
        content: '',
        image: ''
    });


    const { register, handleSubmit, formState: { errors } } = useForm();


    const handleInputChange = (fieldName, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value,
        }));
    };




    const onSubmit = async () => {
        console.log(formData);

        try {
            const response = await Axios.post('/api/addHistory', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            if (response.status === 200 || 201) {
                notify('History added Successfully');
                setTimeout(() => {
                    navigate('/about')
                }, 1500);
            }
        } catch (error) {
            notifyErr('Something went wrong')
            console.error(error);
        }
    }


    return (
        <>
            <div className='history-page'>

                <Helmet>
                    <title>Add History</title>
                </Helmet>
                <section className="blog-add">
                    <div className="row">
                        <div className="col-lg-6">
                            <span className='p-3 fw-bold'>Add History</span>
                        </div>

                    </div>
                </section>
                <ToastContainer />
                <section class="card  cardSectionBorder mt-3">
                    <div className=' m-2'>
                        <Form className='row' onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={formData?.title || ''}
                                    {...register("title", {
                                        required: true,
                                        onChange: (e) => handleInputChange('title', e.target.value)
                                    })}
                                />

                                {errors.title && (
                                    <div className='errMsg text-danger'>Title is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3 col-6">
                                <Form.Label>Year</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={formData?.year || ''}
                                    {...register("year", {
                                        required: true,
                                        onChange: (e) => handleInputChange('year', e.target.value)
                                    })}
                                />

                                {errors.title && (
                                    <div className='errMsg text-danger'>year is required</div>
                                )}
                            </Form.Group>

                            <Form.Group className="mt-2 col-lg-12 col-md-12 col-sm-12 col-12">
                                <Form.Label>Content</Form.Label>
                                <Form.Control className="" rows="5" as="textarea"
                                    value={formData?.content || ''}
                                    {...register("content", {
                                        required: true,
                                        onChange: (e) => handleInputChange('content', e.target.value)
                                    })}
                                />

                                {errors.content && (
                                    <div className='errMsg text-danger'>content is required</div>
                                )}

                            </Form.Group>

                            <Form.Group
                                className="mt-2"
                            >

                                <div className='row'>
                                    <div className='col-6'>
                                        <Form.Label>Image</Form.Label>
                                        <Form.Control type="file" name="file"
                                            {...register("image", {
                                                required: true,
                                                onChange: (e) => handleInputChange('image', e.target.files[0])
                                            })}
                                        />

                                        {errors.image && (
                                            <div className='errMsg text-danger'>Please Select Image</div>
                                        )}

                                    </div>

                                    <div className='col-6 text-center mb-3 mt-3'>
                                        <Form.Label>Image Preview</Form.Label>
                                        <div className='p-3'>

                                            {formData?.image == "" ?

                                                <img src={`https://jewellery.reflomsolutions.com/assets/no-data-b84b69a5.png`} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                :
                                                <img src={URL.createObjectURL(formData?.image)} style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                            }
                                        </div>

                                    </div>
                                </div>

                            </Form.Group>


                            <hr></hr>
                            <div className='btn-d d-flex justify-content-center'>
                                <Button type='submit' className='text-white w-20' variant='contained' style={{ backgroundColor: '#2e2d3d', borderRadius: '0' }} >
                                    Add History
                                </Button>
                            </div>
                        </Form>





                    </div>
                </section>

            </div>
        </>
    )
}

export default AddHistory